import React, { ReactElement } from "react";
import * as constants from "../../../styles/style";
import { URL_IN_COMMENT_REGEX } from "../constants";
import shortid from "shortid32";

type Props = {
  text: string;
};

const TextWithUrls = (props: Props) => {
  const { text } = props;

  const urls = text.match(URL_IN_COMMENT_REGEX);

  const textContent = buildContent([], urls as any, text);

  return (
    <div
      style={{
        ...constants.styles.stdSize,
        color: "#000000",
        whiteSpace: "pre-line",
      }}
    >
      {textContent}
    </div>
  );
};

export default TextWithUrls;


const buildContent = (content: ReactElement[] = [], urls: string[] = [], text: string = "") => {
  const currentUrl = urls[0];

  const textParts = text.split(currentUrl);
  if(textParts[0]) {
    const span = getSpan(textParts[0]);
    content.push(span);
  }
  const link = getLink(urls[0]);
  content.push(link);

  const restUrls = urls.slice(1);
  if (restUrls.length) {
    buildContent(content, restUrls, textParts[1]);
  }
  return content;
};

const getLink = (link: string) => <a key={shortid.generate()} href={link} referrerPolicy='no-referrer' target='_blank' rel='noreferrer'>{link}</a>;
const getSpan = (content: string) => <span key={shortid.generate()}>{content}</span>;
