import React from "react";
import { age, getCropAge } from "farmerjoe-common/lib/utils/Crop";
import I18n from "../../language/i18n";
import { Crop } from "../../flowTypes";

type Props = {
  crop: Crop;
  style: React.CSSProperties;
  fullText?: boolean;
};

const CropAge = (props: Props) => {

  const { crop, style = {}, fullText = false } = props;
  let time = "";

  if (fullText) {
    time = I18n.t("x_days_full", { count: getCropAge(crop) });
  } else {
    time = I18n.t("x_days", { days: getCropAge(crop) });
  }

  return (
    <div className="crop-age">
      <span style={style}>{time}</span>
    </div>
  );
};

export default CropAge;
