import React from "react";
import { NotACropState } from "farmerjoe-common/lib/flow/types";

import Icon from "./Icon";
import type { Crop } from "../../flowTypes";

type Props = {
  crop: Crop;
  defaultIconStyle?: Record<string, any>;
  activeStyle?: Record<string, any>;
  noCropStyle?: Record<string, any>;
  planStyle?: Record<string, any>;
  harvestedStyle?: Record<string, any>;
};

/**
 * Renders the correct icon for a crop based on it's not_a_crop type
 */
const CropIcon  = (props: Props) => {
  const {
    crop,
    activeStyle,
    harvestedStyle,
    noCropStyle,
    planStyle,
    defaultIconStyle,
  } = props;

  const iconStyle = {
    marginLeft: 0,
    marginRight: 5,
    fontSize: 16,
    color: "#000",
    ...defaultIconStyle,
  };

  if (
    crop.not_a_crop === NotACropState.HarvestedCrop &&
      (typeof crop.harvested_on === "undefined" || crop.harvested_on === null)
  ) {
    return (
      <Icon
        style={{ ...iconStyle, ...activeStyle }}
        name="leaf"
        iconType={"fj"} /* testID={"plantedCropIcon"} */
      />
    );
  }

  if (crop.harvested_on) {
    return (
      <Icon
        style={{ ...iconStyle, ...harvestedStyle }}
        name="md-checkmark-circle-outline" /* testID={"harvestedCropIcon"} */
      />
    );
  } 

  if (crop.not_a_crop === NotACropState.NotACrop) {
    return <div style={{ ...{ width: 19 }, ...noCropStyle }}></div>;
  } 

  if (crop.not_a_crop === NotACropState.PlannedCrop) {
    return (
      <Icon
        iconType={"fj"}
        style={{ ...iconStyle, ...planStyle }}
        name="time" /* testID={"plannedCropIcon"} */
      />
    );
  }

  return null;
};

export default CropIcon;
