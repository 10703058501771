import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import { NotACropState, CropState } from "farmerjoe-common/lib/flow/types";
import { NO_CROP_NAME } from "farmerjoe-common/lib/constants/crops";
import { outputDate } from "farmerjoe-common";
import { getViewForField } from "farmerjoe-common/lib/selectors/fields";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCropAge } from "farmerjoe-common/lib/utils/Crop";

import "./style.css";
import Icon from "../Common/Icon";
import CropHeaderContainer from "../Common/CropHeaderContainer";
import CropIcon from "../Common/CropIcon";
import { LoadingIcon } from "../Loading/Loading";
import DropDown from "../DropDown/DropDown";

import * as constants from "../../styles/style";
import I18n from "../../language/i18n";

const styles = constants.styles;

const cropIconStyle = {
  fontSize: 15,
  color: "#000",
  marginLeft: 0,
  marginRight: 5,
  paddingTop: 2,
};

const CommentsHeader  = (props) => {
  const { crop, open, onPrint, disablePrint, printInProgress } = props;
  const [openFilter, setOpenFilter] = useState(false);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const dropDownRef = useRef(null);

  return (
    <CropHeaderContainer
      color={crop.color}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 10,
        borderRadius: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#FFFFFF",
        cursor: "pointer",
        width: "100%",
      }}>
      {onPrint
        ? (
          printInProgress
            ? (
              <LoadingIcon style={{ height: 37 }} />
            )
            : (
              <button
                className="print btn btn-secondary"
                style={{ marginRight: 5 }}
                onClick={onPrint}
                disabled={disablePrint}>
                <i className="fa fa-print" />
              </button>
            )
        )
        : null}


      <DropDown
        ref={dropDownRef}
        button={
          <button
            className={`print btn btn-secondary ${filterIsActive ? "active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenFilter(!openFilter);
            }}
          >
            <i className="fa fa-filter" />
          </button>
        }

        menuClassName={""}
        menuPosition={"dropdown-menu-left"}
        menuAnimation={"scale-up-left"}
        className={""}
        showInPortal={true}
        show={openFilter}
        onHide={() => setOpenFilter(false)}
      >
        <CommentFilters
          onFilterApply={(filters) => {
            setOpenFilter(false);
            props.onFilterChanged(filters);
            if (filters.length > 0) {
              setFilterIsActive(true);
            } else {
              setFilterIsActive(false);
            }
          }}
        />
      </DropDown>


      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}>
          <CropIcon
            crop={crop}
            activeStyle={cropIconStyle}
            harvestedStyle={cropIconStyle}
            planStyle={cropIconStyle}
          />
          <span
            style={{
              ...styles.stdSize,
              ...{
                fontStyle: crop.not_a_crop === NotACropState.PlannedCrop ? "italic" : "normal",
                backgroundColor: "transparent",
              },
            }}>
            {crop.not_a_crop === NotACropState.NotACrop && crop.name === NO_CROP_NAME ? I18n.t("crop.without") : crop.name}
          </span>
        </div>
        {crop.art
          ? (
            <div style={{ marginLeft: 19 }}>
              <span
                style={{
                  ...styles.small,
                  ...{
                    textAlign: "left",
                  },
                }}>
                {crop.art}
              </span>
            </div>
          )
          : null}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // width: crop.harvested_on ? 130 : 80,
          backgroundColor: "transparent",
          // justifyContent: crop.harvested_on ? 'space-between' : 'flex-end',
          alignItems: "center",
          marginLeft: "auto",
        }}>
        {renderHarvestedYear(crop.harvested_on)}
        {renderCropAge(crop, open)}
      </div>
    </CropHeaderContainer>
  );
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  let { crop } = ownProps;

  if (crop) {
    const cropId = crop.state === CropState.Abandoned ? crop.realCropId : crop.key;
    const fieldId = crop.state === CropState.Abandoned ? crop.field.realFieldId : crop.field_id;

    const fieldView = getViewForField(state, openCompany, fieldId);

    if (fieldView) {
      const { activeCrops } = fieldView;
      crop = { ...crop, ...activeCrops[cropId] };
    }
  }

  return {
    crop,
  };
};
export default connect(selector)(CommentsHeader);

const CommentFilters = (props) => {
  const { onFilterApply } = props;
  const [selectedFilters, setSelectedFilters] = useState({});

  const AVAILABLE_FILTERS = [
    {
      label: I18n.t("fertilizer.fertilization"),
      value: "system.fertilizing",
    },
    {
      label: I18n.t("crop.protection"),
      value: "system.wait_time",
    },
    {
      label: I18n.t("bonitur.boniturs"),
      value: "system.bonitur",
    },
    {
      label: I18n.t("images"),
      value: "user.image",
    },
  ].sort((a,b) => a.label.localeCompare(b.label));

  const activeFilters = Object.keys(selectedFilters).filter(key => selectedFilters[key]);

  return (
    <div>
      {AVAILABLE_FILTERS.map((filter, index) => (
        <div
          key={`${index}-${filter.value}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 1rem",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelectedFilters({
              ...selectedFilters,
              [filter.value]: !selectedFilters[filter.value],
            });
          }}
        >
          <div>{filter.label}</div>
          <div
            style={{ width: 40 }}
            className={"d-flex justify-content-center"}>
            {selectedFilters[filter.value]
              ? (
                <Icon
                  name={"check"}
                  iconType={"fa"}
                  style={{ color: constants.FJAPPLEGREEN }}
                />
              ) 
              : <Icon
                name={"square"}
                iconType={"far"}
              />
            }
          </div>
        </div>
      ))}
      <div style={{
        marginTop: "1rem",
        marginRight: "0.5rem",
        float: "right",
      }}>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onFilterApply(activeFilters);
          }}>
          {I18n.t("done")}
        </button>
      </div>
    </div>
  );
};

const renderHarvestedYear = (harvested_on) => {
  if (!harvested_on) {
    return null;
  }

  return (
    <span
      style={{
        ...styles.small,
        ...{
          marginLeft: 10,
          marginRight: 10,
          textAlign: "left",
          backgroundColor: "transparent",
        },
      }}>
      {harvested_on ? outputDate(harvested_on, "YYYY") : null}
    </span>
  );
};

const renderCropAge = (crop, open) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <span
        style={{
          ...styles.small,
          ...{
            textAlign: "right",
            marginRight: 5,
            backgroundColor: "transparent",
            fontWeight: "bold",
            width: 60,
          },
        }}>
        {I18n.t("x_days", { days: getCropAge(crop) })}
      </span>
      <div style={{ width: 20 }}>
        {open
          ? (
            <Icon
              iconType={"fj"}
              style={{
                fontSize: 14,
                marginTop: 2,
                marginLeft: 5,
                backgroundColor: "transparent",
              }}
              name="arrow_down"
            />
          )
          : (
            <Icon
              iconType={"fj"}
              style={{
                fontSize: 14,
                marginTop: 2,
                marginLeft: 5,
                backgroundColor: "transparent",
              }}
              name="arrow_up"
            />
          )}
      </div>
    </div>
  );
};
