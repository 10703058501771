import React, { PureComponent } from "react";
import { debounce, find, get, isEqual, some } from "lodash-es";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import moize from "moize";

import { filters } from "farmerjoe-common/lib/actions/actions";
import * as fieldActions from "farmerjoe-common/lib/actions/field";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getOpenFieldId } from "farmerjoe-common/lib/selectors/selectors";
import { getColor } from "farmerjoe-common/lib/utils/Colors";
import type {
  LatLng,
} from "farmerjoe-common/lib/flow/types";

import Marker from "./Marker";
import MarkerCompany from "./MarkerCompany";
import Polygon from "./Polygon";
import { fieldPolygonOptions } from "./Map";
import DistanceMarker from "./DistanceMarker";
import MapControl, {
  BOTTOM_CENTER,
  ControlButton,
  ControlContainer,
} from "./MapControl";
import ClusterMarker from "./ClusterMarker";
import CropAge from "../Common/CropAge";
import Waittime from "../WaitTime/Waittime";
import withRouter from "../Router/withRouter";
import { truncate } from "../../utils/String";
import * as constants from "../../styles/style";
import {
  appPosToLatLng,
  boundsToPixels,
  compouteBoundsAreaInMeters,
  geoJsonToAppPos,
  latLngToMapPos,
  pixelsToBounds,
  getAreaSizeFromMarker,
} from "../../utils/Map";
import I18n from "../../language/i18n";
import type {
  ClusterFeature,
  MapFilter,
  MarkerFeature,
  Marker as MarkerType,
} from "../../flowTypes";
import SuperClusterWorker from "../../utils/supercluster.worker";

const ZOOM_LEVEL_SHOW_POLYGONS = 11;
const ZOOM_LEVEL_SHOW_MARKERS_WHEN_DISABLED = 15;
const MAP_PADDING_FOR_MARKER_PAN_TO = 200;

type Props = {
  fitBoundaries?: boolean;
  displayFilter?: boolean;
  zoomedIn?: boolean;
  navigate?: boolean;
  markers: Array<MarkerType>;
  filter?: MapFilter;
  hideDistanceMarkers?: boolean;
  hideControls?: boolean;
  hideResetFilter?: boolean;
  onMarkerPress?: (arg0: MarkerType) => void;
  history?: Record<string, any>;
  openCompany?: string;
  waittimes?: Record<string, any>;
  hideMarkers?: boolean;
  actions?: Record<string, any>;
  openFieldId?: string | null;
  isMapPage?: boolean;
  openMarker?: MarkerType;
  map: google.maps.Map;
  baseColors?: boolean;
};

type State = {
  open: string | null;
  zoom: number | null;
  center: LatLng | null;
  clusters: Array<MarkerFeature | ClusterFeature>;
};

class MapMarkers extends PureComponent<Props, State> {
  static defaultProps = {
    hideControls: false,
    hideResetFilter: false,
    ignoreFilter: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: this.props.openFieldId || null,
      zoom: null,
      center: null,
      clusters: [],
    };
    this.worker = new SuperClusterWorker();
    this.workerReady = false;
    this.worker.onmessage = this.workerOnMessage;
  }

  worker: Worker;
  workerReady: boolean;
  mapClickTimeout: any;
  mapBoundsListener: any;
  updateMarkersTimeout: any;
  mapOnClickListener: any;

  filterMarkers = moize(
    (markers, mapBounds) => {
      if (mapBounds) {
        markers = markers.filter(m => m.position).filter(marker => {
          return (
            mapBounds.contains(appPosToLatLng(marker.position)) ||
            (marker.polygon &&
              some(this.preparePolygonPath(marker.polygon), p =>
                mapBounds.contains(p),
              ))
          );
        });
      }

      return markers;
    },
    {
      maxSize: 1,
      matchesKey: (newArgs, oldArgs) => {
        return (
          newArgs[0] === oldArgs[0] &&
          ((!newArgs[1] && !oldArgs[1]) ||
            (newArgs[1] && oldArgs[1] && isEqual(
              latLngToMapPos(newArgs[1].getSouthWest()),
              latLngToMapPos(oldArgs[1].getSouthWest()),
            ) &&
              isEqual(
                latLngToMapPos(newArgs[1].getNorthEast()),
                latLngToMapPos(oldArgs[1].getNorthEast()),
              )))
        );
      },
    },
  );

  getFilteredMarkers() {
    const mapBounds = this.props.map.getBounds();
    return this.filterMarkers(this.props.markers, mapBounds);
  }

  componentDidMount() {
    this.mapOnClickListener = (window as any).google.maps.event.addListener(
      this.props.map,
      "click",
      this.onMapClick,
    );
    this.mapBoundsListener = (window as any).google.maps.event.addListener(
      this.props.map,
      "bounds_changed",
      this.debouncedOnUpdateView,
    );
    this.onUpdateView();

    this.workerReady = false;
    this.worker.postMessage({ markers: this.getFilteredMarkers() });
  }

  onMapClick = e => {
    // this event fires before marker click so use setTimeout to reorder it
    this.mapClickTimeout = setTimeout(() => {
      this.setState({
        open: null,
      });
    }, 0);
  };

  componentDidUpdate(prevProps, prevState) {
    const markerClusterer: any = null; // this.context[MARKER_CLUSTERER]
    const previousZoom = prevState.zoom || 0;
    const currentZoom = this.state.zoom || 0;
    if (
      markerClusterer &&
      (previousZoom > currentZoom || prevState.center !== this.state.center)
    ) {
      // clusterer redrawing is disabled when adding markers to a clusterer, do it once here
      markerClusterer.redraw_();
    }

    if (
      this.props.filter !== prevProps.filter ||
      this.props.markers !== prevProps.markers
    ) {
      this.workerReady = false;
      // map bounds at this time don't necessarily correspond to what the map will display, so update markers in a setTimeout
      this.updateMarkersTimeout = setTimeout(() => {
        this.worker.postMessage({ markers: this.getFilteredMarkers() });
      }, 0);
    }

    if (
      this.props.openFieldId !== prevProps.openFieldId &&
      this.props.isMapPage
    ) {
      this.setState({ open: this.props.openFieldId as any });

      const marker = find(this.props.markers, { key: this.props.openFieldId });

      if (marker) {
        const map = this.props.map;
        const mapBounds = map.getBounds();
        let shouldPanTo = true;

        if (mapBounds) {
          // pan to marker only if it's not in the map view minus some padding

          const { width, height } = boundsToPixels(map, mapBounds as any);
          const padding = MAP_PADDING_FOR_MARKER_PAN_TO;

          if (width > 2 * padding && height > 2 * padding) {
            const p1 = new (window as any).google.maps.Point(padding, padding);
            const p2 = new (window as any).google.maps.Point(
              width - padding,
              height - padding,
            );
            const mapBoundsMinusPadding = pixelsToBounds(map as any, p1, p2);

            if (
              mapBoundsMinusPadding.contains(appPosToLatLng(marker.position))
            ) {
              shouldPanTo = false;
            }
          }
        }

        if (shouldPanTo) {
          map.panTo(appPosToLatLng(marker.position) as any);
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.mapClickTimeout);
    clearTimeout(this.updateMarkersTimeout);
    if (this.mapBoundsListener) {
      (window as any).google.maps.event.removeListener(this.mapBoundsListener);
    }
    this.worker.terminate();
    if (this.mapOnClickListener) {
      (window as any).google.maps.event.removeListener(this.mapOnClickListener);
    }
  }

  render() {
    const { openCompany, hideControls, hideResetFilter } = this.props;
    let fieldMarkerCount = 0;
    const markers = this.getFilteredMarkers();
    let mapped: any[] = [];
    if (markers.length <= 200) {
      mapped = markers.reduce((result, marker) => {
        if (marker.type === "field") {
          fieldMarkerCount++;
        }
        this.reduceMarker(result, marker);
        return result;
      }, []);
    } else {
      mapped = this.state.clusters.reduce((result: any[], feature: any) => {
        if (feature.properties.cluster) {
          fieldMarkerCount += get(
            feature.properties.cluster,
            "properties.point_count",
          );
          // $FlowFixMe
          const clusterFeature: ClusterFeature = feature;
          result.push(
            <ClusterMarker
              map={this.props.map}
              key={clusterFeature.properties.groupId + "-" + clusterFeature.id}
              cluster={clusterFeature}
              onClick={() => {
                this.worker.postMessage({
                  getClusterExpansionZoom: clusterFeature.properties.cluster_id,
                  center: clusterFeature.geometry.coordinates,
                  groupId: clusterFeature.properties.groupId,
                });
              }}
            />,
          );
        } else {
          fieldMarkerCount++;
          // $FlowFixMe
          this.reduceMarker(result, feature.properties.marker);
        }
        return result;
      }, []);
    }

    const showCrops = get(this.props.filter, "showCrops");
    const search = get(this.props.filter, "search");
    return (
      <React.Fragment>
        {mapped}
        {!hideControls &&
        !hideResetFilter &&
        fieldMarkerCount === 0 &&
        (search || (showCrops && showCrops.length !== 3))
          ? (
            <MapControl position={BOTTOM_CENTER} style={{ order: 3 }} map={this.props.map}>
              <ControlContainer className="map-toolbar-container reset-filter-container">
                <ControlButton
                  onClick={() => {
                    return this.props.actions?.filters(openCompany, {
                      search: "",
                      showCrops: [0, 1, 2],
                    });
                  }}
                  style={{ marginLeft: 20 }}>
                  {I18n.t("resetFilter")}
                </ControlButton>
              </ControlContainer>
            </MapControl>
          )
          : null}
      </React.Fragment>
    );
  }

  reduceMarker = (result, marker: any) => {
    const { filter, history, openCompany, waittimes, hideMarkers } = this.props;
    const zoom = this.state.zoom || 0;
    const map = this.props.map;
    const mapBounds = map.getBounds();
    const mapSquareMeters =
    (map.getZoom() as number) > 2 // at zoom level <= 2 you have more square meters than a js number can hold
      ? mapBounds
        ? compouteBoundsAreaInMeters(mapBounds as any)
        : 0
      : Number.MAX_SAFE_INTEGER;

    if (marker.type === "company") {
      result.push(
        <MarkerCompany
          key={marker.key}
          marker={marker}
          map={map}
          onClick={() => history?.push(`/company/${openCompany}/info`)}
        />,
      );
    } else {
      const isOpen = this.state.open === marker.key;
      if (!hideMarkers) {
        result.push(this.createMarker(marker, filter, waittimes, isOpen));
      }
      const polygon = marker.polygon;

      // areaSize is in hectares, convert to meters
      // only show polygon if it's area in the viewport is above certain percentage of the total viewing area
      if (
        polygon &&
        (hideMarkers ||
          (!hideMarkers &&
            zoom >= ZOOM_LEVEL_SHOW_POLYGONS &&
            (marker.areaSize * 10000) / mapSquareMeters > 0.0001))
      ) {
        const path = this.preparePolygonPath(polygon);
        result.push(
          <Polygon
            path={path}
            key={marker.key + "-polygon"}
            options={this.getPolygonOptions(
              get(marker, "activeCrop.color", "noCrop"),
              isOpen,
              this.props.baseColors,
            )}
            map={this.props.map}
            onClick={this.onClickPolygon.bind(this, marker)}
          />,
        );

        if (
          !this.props.hideDistanceMarkers &&
          zoom >= ZOOM_LEVEL_SHOW_MARKERS_WHEN_DISABLED
        ) {
          for (let i = 0; i < polygon.length; i++) {
            result.push(
              <DistanceMarker
                key={marker.key + "-polygon-distance-" + i}
                start={polygon[i]}
                map={this.props.map}
                end={polygon[(i + 1) % polygon.length]}
              />,
            );
          }
        }
      }
    }
  };

  createMarker = moize((marker, filter, waittimes, isOpen) => {
    const markerSize = getAreaSizeFromMarker(marker);
    let description = (
      <span style={{ whiteSpace: "nowrap" }}>{markerSize || 0} ha</span>
    );
    const filterBy = get(filter, "markerTitle", "ha");

    if (filterBy === "markerTitle") {
      description = (
        <span style={{ whiteSpace: "nowrap" }}>
          {truncate(marker.name, 20)}
        </span>
      );
    } else if (filterBy === "cropAge") {
      description = (
        <CropAge
          crop={marker.activeCrop}
          style={{ whiteSpace: "nowrap" }}
        />
      );
    } else if (filterBy === "waitTime") {
      description = <span style={{ width: 30, height: 20 }}></span>;
      if (waittimes && waittimes[marker.key]) {
        description = (
          <Waittime
            iconStyle={{ fontSize: 14, color: "#000" }}
            style={{
              color: "#000",
              ...constants.styles.stdSize,
              whiteSpace: "nowrap",
            }}
            waitTimes={waittimes[marker.key] || []}
          />
        );
      }
    }

    return (
      <Marker
        key={marker.key}
        description={description}
        marker={marker}
        map={this.props.map}
        onClick={this.onClickMarker.bind(this, marker)}
        isOpen={isOpen}
        onOpen={() => this.setState({ open: marker.key })}
        onClose={() => this.setState({ open: null })}
      />
    );
  });

  onClickPolygon = (marker, e) => {
    this.onClickMarker(marker, e);
    this.setState({ open: marker.key });
  };

  onClickMarker = (marker, e) => {
    clearTimeout(this.mapClickTimeout); // prevent closing marker
    this.props.onMarkerPress && this.props.onMarkerPress(marker);
  };

  onUpdateView = () => {
    const map = this.props.map;
    const center = map.getCenter() as any;
    const zoom = map.getZoom();
    if (this.state.zoom !== zoom || !isEqual(center, this.state.center)) {
      this.setState({
        center,
        zoom: zoom as any,
      });
      this.workerUpdate();
    }
  };

  debouncedOnUpdateView = debounce(this.onUpdateView, 500);

  preparePolygonPath = moize(polygon => {
    return polygon.map(pos => appPosToLatLng(pos));
  });

  getPolygonOptions = moize((color, isOpen, useBaseColors) => {
    const fillColor = getColor(color, 0.3);
    const strokeColor = useBaseColors ? "white" : getColor(color, 1);
    return {
      ...fieldPolygonOptions,
      fillColor,
      strokeColor: isOpen ? "#1e88e5" : strokeColor,
      strokeWeight: isOpen ? 4 : 2,
      clickable: true,
    };
  });

  workerOnMessage = e => {
    const map = this.props.map;
    if (e.data.ready) {
      this.workerReady = true;
      this.workerUpdate();
    } else if (e.data.expansionZoom) {
      map?.setZoom(e.data.expansionZoom);
      map?.panTo(appPosToLatLng(geoJsonToAppPos(e.data.center)) as any);
    } else {
      this.setState({
        clusters: e.data,
      });
    }
  };

  workerUpdate() {
    if (!this.workerReady) {
      return;
    }
    const map = this.props.map;
    const bounds = map.getBounds();
    const northEast = bounds?.getNorthEast();
    const southWest = bounds?.getSouthWest();
    this.worker.postMessage({
      bbox: [
        southWest?.lng(),
        southWest?.lat(),
        northEast?.lng(),
        northEast?.lat(),
      ],
      zoom: map?.getZoom(),
    });
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
};

// keep the filter object outside of the selector to prevent unnecessary rerendering due to different instances
const defaultFilter = {
  search: "",
  mapType: "hybrid",
  showCrops: [0, 1, 2],
  markerTitle: "ha",
};
const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  return {
    openCompany: openCompany,
    filter: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany]
      : defaultFilter,
    openFieldId: getOpenFieldId(state),
  };
};

export default compose<typeof MapMarkers>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(MapMarkers);
