import React, { useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { get } from "lodash-es";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getCompanyGroupProfileForLoggedInUser,
  getGroupUsers,
  usersWithAccessToField,
} from "farmerjoe-common/lib/selectors/user";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { isNotMainGroup } from "farmerjoe-common/lib/utils/firestoreRedux/Utils";
import { openGroup } from "farmerjoe-common/lib/actions/group";
import { getUsersQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Users";
import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
} from "farmerjoe-common/lib/selectors/groups";
import { UserRole } from "farmerjoe-common/lib/flow/types";
import { fieldSharedBySelector } from "farmerjoe-common/lib/selectors/fields";
import { isFieldOwner } from "farmerjoe-common/lib/utils/Field";
import { outputDate } from "farmerjoe-common";

import FieldInfoCoordinatesSubmenu from "./FieldInfoCoordinatesSubmenu";
import Icon from "../Common/Icon";
import AvatarName from "../Users/AvatarName";
import EmployeesOnField from "../../containers/Employees/EmployeesOnField";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import type { Employee, Field } from "../../flowTypes";
import useFieldCollaborators from "../../hooks/useFieldCollaboratos";

type Props = {
  field: Field;
  onClick: (...args: Array<any>) => any;
  users: [Employee];
  open: boolean;
  myCompanyProfile: Employee;
  actions: {
    openGroup: typeof openGroup;
  };
  openCompany: string;
  sharedBy?: any;
};

const rowLabel = {
  ...constants.styles.stdSize,
  ...constants.styles.strong,
  ...constants.styles.mutedLight,
  minWidth: 170,
  display: "inline-block",
};

const FieldInfo = (props: Props) => {
  const { field, myCompanyProfile, openCompany, sharedBy, open } = props;
  const runHook = get(field, "collaborators", false);
  const fieldCollaborators = useFieldCollaborators(field.collaborators as any, runHook);
  const [showEmployeesOnField, setShowEmployeesOnField] = useState(false);

  if (!open) {
    return null;
  }

  const producerKey = get(field, "groupMeta.key");
  const producerName = get(field, "groupMeta.name");
  const traderKey = get(field, "traderMeta.key");
  const traderName = get(field, "traderMeta.name");
  // if the currently logged user belongs to the main group and the field he looks at belongs to a producer
  const canViewProducer =
      myCompanyProfile &&
      !isNotMainGroup(myCompanyProfile.group_id) &&
      isNotMainGroup(producerKey);

  const renderDataRow = (label, data) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}>
        <span style={rowLabel}>{label}: </span>
        <span style={{ ...constants.styles.stdSize }}> {data}</span>
      </div>
    );
  };

  const renderUsers = () => {
    const { users } = props;
    const advisor = users.filter((user) => user.role === UserRole.Advisor);
    const normal = users.filter((user) => user.role !== UserRole.Advisor);

    if (advisor.length === 0 && normal.length === 0) {
      return null;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {advisor.length ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={rowLabel}>{I18n.t("employees.advisors")}: </span>
            <div style={{ marginTop: 5 }}>
              {advisor.map((user) => {
                return (
                  <div style={{ marginBottom: 10 }} key={user.key}>
                    <AvatarName
                      uid={user.key}
                      name={user.name}
                      groupId={user.group_id || COMPANY_MAIN_GROUP_KEY}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <div
          style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}
        >
          <span style={rowLabel}>{I18n.t("employees.plural")}: </span>
          <div style={{ marginTop: 5 }}>
            {normal.map((user) => {
              return (
                <div style={{ marginBottom: 10 }} key={user.key}>
                  <AvatarName
                    uid={user.key}
                    name={user.name}
                    groupId={user.group_id || COMPANY_MAIN_GROUP_KEY}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderGroupInfo = (label, companyId, key, name, type) => {
    const { actions } = props;

    return (
      <div style={{ flexDirection: "row", paddingBottom: 5 }}>
        <span style={rowLabel}>{label}: </span>

        <Link
          key={"producer"}
          to={`/company/${companyId}/${type}/${key}`}
          onClick={() => {
            actions.openGroup(key);
          }}
        >
          <span style={{ ...constants.styles.stdSize }}>{name}</span>
        </Link>
      </div>
    );
  };

  const renderOwnerInfo = () => {
    const isOwnerOfField = isFieldOwner(field.company_id, openCompany);
    if (isOwnerOfField) {
      return null;
    }

    if (!sharedBy) {
      return null;
    }

    const name = sharedBy.company ? sharedBy.company.name : sharedBy.name;

    return (
      <div style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}>
        <span style={rowLabel}>
          {I18n.t("fieldSharing.fieldInfoModal.header")}:{" "}
        </span>
        <Link
          to={`/company/${openCompany}/field/${field.key}/collaborators/${sharedBy.company.key}`}
        >
          <span style={{ ...constants.styles.stdSize }}>{name}</span>
        </Link>
      </div>
    );
  };

  const renderSharedWith = () => {
    if (!fieldCollaborators.length) {
      return null;
    }

    const links = fieldCollaborators.map((fc: { key: string, name: string}, key) => {
      return (
        <div key={key} style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}>
          <Link
            to={`/company/${openCompany}/field/${field.key}/collaborators/${fc.key}`}
          >
            <span style={{ ...constants.styles.stdSize, marginRight: "0.5em" }}>{fc.name}</span>
          </Link>
        </div>
      );
    });

    return (
      <div>
        <div>
          <span style={rowLabel}>
            {I18n.t("fieldFiltersModal.showSharedWithCompanies")}:{" "}
          </span>
        </div>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
          {links}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        zIndex: 1,
        backgroundColor: "transparent",
        boxShadow: "1px 0px 20px rgba(0, 0, 0, 0.08)",
      }}
    >
      <div
        style={{
          padding: 10,
          paddingTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: constants.FJBORDERCOLOR,
          backgroundColor: "#FFF",
        }}
      >
        {sharedBy ? renderOwnerInfo() : null}
        {renderSharedWith()}
        {canViewProducer &&
            renderGroupInfo(
              I18n.t("producers.single"),
              openCompany,
              producerKey,
              producerName,
              "producer",
            )}
        {traderKey
          ? renderGroupInfo(
            I18n.t("traders.single"),
            openCompany,
            traderKey,
            traderName,
            "trader",
          ): null }

        {(field as any).irrigation
          ? renderDataRow(
            I18n.t("irrigation.irrigationType"),
            I18n.t(`irrigation.types.${(field as any).irrigation}`),
          ) : null}

        {(field as any).waterOrigin
          ? renderDataRow(
            I18n.t("irrigation.waterOrigin"),
            I18n.t(
              `irrigation.waterOriginTypes.${(field as any).waterOrigin}`,
            ),
          ): null}

        {renderDataRow(I18n.t("crop.sown_on"), outputDate(field.activeCrop.sown_on))}

        <div
          style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}
        >
          <span style={rowLabel}>{I18n.t("coordinates")}: </span>
          <FieldInfoCoordinatesSubmenu field={field} />
        </div>

        {field.land_id
          ? renderDataRow(I18n.t("field.number"), field.land_id) : null}

        {field.notes
          ? renderDataRow(I18n.t("field.number"), field.notes) : null}

        {renderUsers()}

        {isAdmin(myCompanyProfile) ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-secondary"
              onClick={() => setShowEmployeesOnField(true)}
            >
              <Icon name={"md-person-add"} />{" "}
              {I18n.t("employees.addEmployee")}
            </button>
          </div>
        ) : null}
      </div>

      {showEmployeesOnField ? (
        <EmployeesOnField
          show={showEmployeesOnField}
          onClose={() => setShowEmployeesOnField(false)}
        />
      ) : null}
    </div>
  );

};

const wrappedFieldInfo = firestoreConnect((props) => {
  const { myCompanyProfile } = props;
  // if we have a company profile and we are allowed to view the users
  // then get them from the collection
  if (myCompanyProfile && get(myCompanyProfile, "views.users", true)) {
    const users = getUsersQuery(props.openCompany, props.browsingGroup);
    return [users];
  }

  return [];
})(FieldInfo);

const selector = (state, ownProps) => {
  const openCompanyId = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompanyId);
  const field = ownProps.field;
  const users = usersWithAccessToField(
    getGroupUsers(
      state,
      field.company_id,
      get(field, "group_id", COMPANY_MAIN_GROUP_KEY),
    ),
    field.key,
  );
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompanyId,
  );
  const sharedBy = fieldSharedBySelector(
    state,
    openCompanyId,
    state.firebase.auth.uid,
    field.key,
  );

  return {
    field,
    users,
    myCompanyProfile,
    browsingGroup,
    openCompany: openCompanyId,
    sharedBy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openGroup,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(selector, mapDispatchToProps)(wrappedFieldInfo);
