import React, { Component } from "react";
import PropTypes from "prop-types";

import { getColor } from "farmerjoe-common/lib/utils/Colors";

// TODO: improve typings
type Props = any;
type State = any;

export default class CropHeaderContainer extends Component<Props, State> {
  static propTypes = {
    color: PropTypes.string.isRequired,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  render() {
    const { color, children, style } = this.props;

    return (
      <div
        {...this.props}
        className={"crop-header-container"}
        style={{
          // In the first versions we were storing the color HEX value in the db. Later we changed to strings
          // If the string is not in the array, then we are most probably dealing with a HEX value
          backgroundColor: getColor(color),
          ...style,
        }}>
        {children}
      </div>
    );
  }
}
