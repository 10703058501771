import React from "react";

import Icon from "../Common/Icon";
import CropIcon from "../Common/CropIcon";
import CropHeaderContainer from "../Common/CropHeaderContainer";

import * as constants from "../../styles/style";
import I18n from "../../language/i18n";

type Props = {
  title: string;
  rowData?: any;
  open?: any;
};

const CropHeader = (props: Props) => {
  const { rowData, open, title } = props;

  const crop = rowData.fields[0].activeCrop;

  let headerTitle = title;
  if (title === "without_crop_name" || title === "without_crop_variety") {
    headerTitle = I18n.t(`crop.${title}`);
  }
  if (title === "Ohne Kultur") {
    headerTitle = I18n.t("crop.without");
  }

  // Note: calling reduce on an empty object will throw a typeError
  // calling reduce with a single element and no initial value will initiate one of the
  // callback parameters to undefined and will screw our calculations.
  // That's why convert to array first and provide initial value
  const size = rowData.fields
    .map(el => el.size)
    .reduce((pre = 0, cur = 0) => {
      return pre + cur;
    }, 0)
    .toFixed(2);

  return (
    <CropHeaderContainer color={crop.color}>
      <div
        style={{
          ...constants.styles.containerRow,
          ...{ alignItems: "baseline", width: 200 },
        }}>
        <CropIcon crop={crop} />
        <span
          style={{
            ...constants.styles.stdSize,
            ...{
              backgroundColor: "transparent",
              color: "#000",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "block",
              whiteSpace: "nowrap",
            },
          }}>
          {headerTitle}
        </span>
      </div>
      <span
        style={{
          ...constants.styles.stdSize,
          ...{
            width: 100,
            textAlign: "right",
            paddingRight: 2,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        }}>
        {size}{" "}
      </span>
      <span
        style={{
          ...constants.styles.stdSize,
          ...{
            width: 25,
            textAlign: "left",
            marginRight: 5,
          },
        }}>
          ha
      </span>
      <div
        style={{
          display: "flex",
          paddingTop: 10,
          paddingBottom: 4,
          width: 50,
          alignItems: "center",
          marginRight: 15,
          flexDirection: "column",
          lineHeight: 1,
        }}>
        <div style={{ backgroundColor: "transparent" }}>
          <span
            style={{
              ...constants.styles.small,
              ...{ backgroundColor: "transparent" },
            }}>
            {rowData.fields.length}
          </span>
        </div>
        <div style={{}}>
          {open
            ? (
              <Icon
                iconType={"fj"}
                style={{ backgroundColor: "transparent", fontSize: 10 }}
                name="arrow_down"
              />
            )
            : (
              <Icon
                iconType={"fj"}
                style={{ backgroundColor: "transparent", fontSize: 10 }}
                name="arrow_up"
              />
            )}
        </div>
      </div>
    </CropHeaderContainer>
  );
};

export default CropHeader;
