export const URL_IN_COMMENT_REGEX = /(\b((?:https?|ftp):\/\/|www\.)\S+\b)/g;

export const COMMENT_TYPE_ANALYSIS = "analysis";
export const COMMENT_TYPE_ARCHIVE = "archive";
export const COMMENT_TYPE_BONITUR = "bonitur";
export const COMMENT_TYPE_CROP = "crop";
export const COMMENT_TYPE_FERTILIZING = "fertilizing";
export const COMMENT_TYPE_FIELD = "field";
export const COMMENT_TYPE_FIELD_MARK_ADD = "field_mark_add";
export const COMMENT_TYPE_FIELD_MARK_REMOVE = "field_mark_remove";
export const COMMENT_TYPE_HARVESTED = "harvested";
export const COMMENT_TYPE_IMAGE = "image";
export const COMMENT_TYPE_SYSTEM_FIELD_SHARING = "system.fieldSharing";
export const COMMENT_TYPE_UNARCHIVE = "unarchive";
export const COMMENT_TYPE_USER_FILE = "user.file";
export const COMMENT_TYPE_WAIT_TIME = "wait_time";
export const COMMENT_TYPE_WITHOUT_SINCE = "withoutSince";
export const COMMENT_TYPE_YIELD = "crop.yield";
